<script lang="ts" setup>
import SiteHeader from '@/components/SiteHeader.vue'

withDefaults(
  defineProps<{
    transition?: string | boolean
  }>(),
  {
    transition: 'fade-transition',
  }
)

const modelValue = defineModel<boolean>({ default: true })
</script>

<template>
  <v-dialog
    v-model="modelValue"
    fullscreen
    :scrim="false"
    persistent
    no-click-animation
    content-class="overlay"
    :transition="transition"
  >
    <slot name="header">
      <site-header :overlay="true" />
    </slot>
    <slot />
  </v-dialog>
</template>

<style lang="scss">
.overlay {
  background: $c-white;
}
</style>
